import { NewsletterSubscription } from '../common/models/newsletters/newsletter-subscription.model';
import { NewsletterSubscriptionResponse } from '../common/models/newsletters/newsletter-subscription-response.model';
import { VueLogger } from 'vue-logger-plugin/dist/types/logger';
import { FetchService } from './fetch.service';

const endpointBase = 'https://www.familytoday.com/ftapi/newsletter';

export class NewslettersService {
    private logger: VueLogger;
    private fetchService: FetchService;

    constructor(logger: VueLogger, fetchService: FetchService) {
        this.logger = logger;
        this.fetchService = fetchService;
    }

    /**
     * Subscribe user to newsletters
     * @param subscription subscription object to be sent to server
     */
    async subscribe(
        subscription: NewsletterSubscription
    ): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug('Posting subscription request to server');
        const result =
            await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/subscribe`,
                subscription
            );
        return result.data;
    }
}
