import { VueLogger } from 'vue-logger-plugin/dist/types/logger';
import { GTMService } from './gtm.service';

const pushnamiConfig = {
    enabled: false,
    apiKey: '5d408517b5050b1bee99c8f4',
    category: 'Push Notifications',
    label: 'Pushnami',
    events: [
        {
            PushnamiEventName: 'permissions-action-subscribed-new',
            GTMEventName: 'pushnamiNewSubscribe',
            AnalyticsAction: 'Accept',
        },
    ],
};

export class PushnamiService {
    private logger: VueLogger;
    private gtm: GTMService;
    constructor(logger: VueLogger, gtm: GTMService) {
        this.logger = logger;
        this.gtm = gtm;
    }

    public init = () => {
        if (!pushnamiConfig.enabled) {
            return;
        }

        this.logger.info('in the init');
        // grab blog details off html
        const htmlTag = document.querySelector('html');

        // Create options object with blog name
        const options = {
            section: '',
        };

        if (htmlTag?.hasAttribute('data-content-section')) {
            options.section =
                htmlTag.getAttribute('data-content-section') || '';
        }

        this.logger.debug('Pushnami Options: ', options);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src =
            'https://api.pushnami.com/scripts/v1/pushnami-adv/' +
            pushnamiConfig.apiKey;
        script.onload = () => {
            window.Pushnami.update(options).prompt();

            // Hook into the defined events and fire analytics events
            for (let i = 0; i < pushnamiConfig.events.length; i++) {
                const event = pushnamiConfig.events[i];
                window.Pushnami.on([event.PushnamiEventName], () => {
                    this.gtm.registerEvent(event.GTMEventName, {
                        category: pushnamiConfig.category,
                        action: event.AnalyticsAction,
                        label: pushnamiConfig.label,
                    });
                });
            }
        };
        document.getElementsByTagName('head')[0].appendChild(script);

        // Unsubscribe from pushnami if URL param is found.
        if (window.location.search.indexOf('pushnamiunsub=true') > -1) {
            this.logger.debug('Pushnami Unsubscribe');
            this.unsubscribe(script);
        }
    };

    private unsubscribe = (script: HTMLScriptElement) => {
        if (!pushnamiConfig.enabled) {
            return;
        }

        script.onload = () => {
            window.Pushnami.unsubscribe();
        };
        this.gtm.registerEvent('pushnamiUnsubscribe', {
            category: pushnamiConfig.category,
            action: 'Unsubscribe',
            label: pushnamiConfig.label,
        });
    };
}
