import { NewsletterSubscriptionResponse } from '../common/models/newslettermanage/newsletter-subscription-response.model';
import { VueLogger } from 'vue-logger-plugin/dist/types/logger';
import { FetchService } from './fetch.service';
import { NewsletterSubscriptionUpdateResponse } from '@/common/models/newslettermanage/newsletter-subscription-update-response.model';

const endpointBase = 'https://www.familytoday.com/ftapi/newsletter';

export class NewsletterManageService {
    private logger: VueLogger;
    private fetchService: FetchService;

    constructor(logger: VueLogger, fetchService: FetchService) {
        this.logger = logger;
        this.fetchService = fetchService;
    }

    /**
     * get back newsletters based on numbers
     * @param newsletters list of newsletter ids
     */
    async getSubscriptions(
        email: string
    ): Promise<NewsletterSubscriptionResponse | null> {
        this.logger.debug(
            'Posting check email subscriptions request to server'
        );

        const data = {
            email,
        };

        const result =
            await this.fetchService.post<NewsletterSubscriptionResponse>(
                `${endpointBase}/getsubscriptions`,
                data
            );

        return result.data;
    }

    /**
     * get back newsletters based on numbers
     * @param newsletters list of newsletter ids
     */
    async updateEmailSubscription(
        email: string,
        subscriptions: number[]
    ): Promise<NewsletterSubscriptionUpdateResponse | null> {
        this.logger.debug(
            'Posting update email subscription request to server'
        );

        const data = {
            email,
            sourceDesc: 3,
            newsletter: subscriptions,
        };

        const result =
            await this.fetchService.post<NewsletterSubscriptionUpdateResponse>(
                `${endpointBase}/updatesubscription`,
                data
            );
        return result.data;
    }
}
