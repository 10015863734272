import { defineAsyncComponent } from 'vue';
import { ComponentDefinition } from '../common/models/app/component-definition.model';

export const components: Array<ComponentDefinition> = [
    {
        tag: 'rad-affix',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-affix" */ `./structure/Affix.vue`
                )
        ),
    },
    {
        tag: 'rad-carousel-article',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-carousel-article" */ `./carousel/Article.vue`
                )
        ),
    },
    {
        tag: 'rad-carousel-article-card',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-carousel-article-card" */ `./carousel/ArticleCard.vue`
                )
        ),
    },
    {
        tag: 'rad-jwplayer',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-jwplayer" */ `./jwplayer/JWPlayer.vue`
                )
        ),
    },
    {
        tag: 'rad-share-v1',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-share-v1" */ `./social/ShareV1.vue`
                )
        ),
    },
    {
        tag: 'rad-site-stats',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-site-stats" */ `./sitestats/SiteStats.vue`
                )
        ),
    },
    {
        tag: 'rad-site-stats-card',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-site-stats-card" */ `./sitestats/SiteStatsCard.vue`
                )
        ),
    },
    {
        tag: 'rad-admiral-login',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-admiral-login" */ `./admiral/Login.vue`
                )
        ),
    },
    {
        tag: 'rad-advertise-with-us',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-advertise-with-us" */ `./emails/AdvertiseWithUs.vue`
                )
        ),
    },
    {
        tag: 'rad-contact-us',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-contact-us" */ `./emails/ContactUs.vue`
                )
        ),
    },
    {
        tag: 'rad-google-recaptcha',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-google-recaptcha" */ `./google/ReCaptcha.vue`
                )
        ),
    },
    {
        tag: 'rad-newsletter-signup-wide',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletter-signup-wide" */ `./newsletters/NewsletterSignupWide.vue`
                )
        ),
    },
    {
        tag: 'rad-newsletter-signup-side-by-side',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletter-signup-side-by-side" */ `./newsletters/NewsletterSignupSideBySide.vue`
                )
        ),
    },
    {
        tag: 'rad-newsletter-signup-video-small',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletter-signup-video-small" */ `./newsletters/NewsletterSignupVideoSmall.vue`
                )
        ),
    },
    {
        tag: 'rad-newsletter-signup-subscribe',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletter-signup-subscribe" */ `./newsletters/NewsletterSignupSubscribe.vue`
                )
        ),
    },
    {
        tag: 'rad-newsletter-signup-manage',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletter-signup-manage" */ `./newsletters/signup/Manage.vue`
                )
        ),
    },
    {
        tag: 'rad-navigation',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-navigation" */ `./navigation/Navigation.vue`
                )
        ),
    },
    {
        tag: 'rad-navigation-sidebar-button',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-navigation-sidebar-button" */ `./navigation/SidebarButton.vue`
                )
        ),
    },
    {
        tag: 'rad-navigation-search',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-navigation-search" */ `./navigation/Search.vue`
                )
        ),
    },
];
